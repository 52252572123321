import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { useEffect, useState } from "react";

const libraries = ["places"];

export const MapView = ({ slug, setStoreLocationOpen }) => {

  const [location, setLocation] = useState({
    lat: 40.7393313,
    lng: -74.0520984,
  });
  // const [storeLocationOpen, setStoreLocationOpen] = useState(false);
  const [locationTime, setLocationTime] = useState(null);

  function isVendorOpen(locationTimeString) {
    const currentTime = new Date();
    const isoString = currentTime.toISOString();
    console.log('currentTimeString', isoString);
    console.log('locationTimeString', locationTimeString);
    const fifteenMinutesAgo = new Date(currentTime);
    fifteenMinutesAgo.setMinutes(currentTime.getUTCMinutes() - 15);

    const inputTime = new Date(locationTimeString);

    if (inputTime >= fifteenMinutesAgo && inputTime <= currentTime) {
      return true;
    }
    return false;
  }

  const fetchVendorLocation = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/storefronts/${slug}/location/`
    );

    if (response.status !== 200) {
      // setError(response.statusText);
      return;
    }

    const response_json = await response.json();
    console.log("fetchVendorLocation response:", response_json);

    const vendor_location = {
      lat: response_json.location_coordinate.coordinates[1],
      lng: response_json.location_coordinate.coordinates[0],
    };
    setLocation(vendor_location);
    setLocationTime(response_json.location_time);
    setStoreLocationOpen(isVendorOpen(response_json.location_time));
  };

  // useEffect(() => {

  //   return () => {
  //     fetchVendorLocation();
  //   }
  // }, [slug])
  useEffect(() => {
    fetchVendorLocation();
  }, [slug]);




  const containerStyle = {
    width: "100%",
    height: "180px",
    borderRadius: "15px",
  };

  const handleMarkerClick = () => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`;
    window.open(googleMapsUrl, "_blank");
  };


  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={15}>
      <MarkerF position={location} onClick={handleMarkerClick} />
    </GoogleMap>
  );
};
