import {
    Button,
    Typography
} from "@mui/material";
import React from 'react';
import "../../../App.css";
import appleLogo from "../../../assets/appStore.png";
import googleLogo from "../../../assets/googlePlay.png";

function DownloadContainerComponent({slug}) {

    function getDeviceType() {
        let deviceType = ""
        if (/Android/i.test(navigator.userAgent)) {
            deviceType = "android"
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            deviceType = "ios"
        } else {
            deviceType = "desktop"
        }
        console.log(`DeviceType is ${deviceType}`)
        return deviceType
    }

    function openApp() {
        const androidAppLink = `intent://sidewa.lk/${slug}#Intent;scheme=https;package=com.sidewalk.sidewalk;end`;
        const iosAppLink = `sidewalk://${slug}`;

        if (getDeviceType() === "android") {
            window.location.href = androidAppLink;
        } else if (getDeviceType() === "ios") {
            window.location.href = iosAppLink;
        } else {
            // Fallback or prompt for download
            alert("Download the app to open this link.");
        }
    }

  return (
      <div className="download-container">
          <div style={{}}>
              <Typography>
                  View menu, photos, reviews and other similar vendors on the Sidewalk
                  App!
              </Typography>
          </div>
          <div className="app-download-container">
              {getDeviceType() === "android" || getDeviceType() === "ios" ?
                  <Button
                      variant="contained"
                      style={{
                          borderRadius: 18,
                          backgroundColor: "#005F73",
                      }}
                      onClick={openApp}
                  >
                      Get Sidewalk app
                  </Button>
                  :
                  <>
                      <img
                          alt="Logo"
                          src={appleLogo}
                          className="app-logo"
                          onClick={() => window.open('https://apps.apple.com/us/app/sidewalk/id6708225418', '_blank')}
                      />
                      <img
                          alt="Logo"
                          src={googleLogo}
                          className="app-logo"
                          onClick={() => window.open('https://play.google.com/store/apps/details?id=com.sidewalk.sidewalk', '_blank')}
                      />
                  </>
              }

          </div>
      </div>
  )
}

export default DownloadContainerComponent