import React from "react";
import { DeviceFrameset } from "react-device-frameset";
import VendorMobileView from "./VendorMobileView";
import "react-device-frameset/styles/marvel-devices.min.css";
// import "react-device-frameset/styles/devices.min.css";
import Grid from "@mui/material/Grid2";
import "../../App.css";

import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import logo_black_with_text_landscape from "../../assets/logo_black_with_text_landscape.png";
import JoinWaitingList from "../components/JoinWaitingList";
import DownloadContainerComponent from "./components/DownloadContainerComponent";
// /Users/manas/Projects/sidewalk/sidewalk-web/src/assets/logo_black_with_text_landscape.png

const VendorDesktopView = () => {
  console.log("VendorDesktopView");

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        color="transparent"
        alignItems="center"
        justifyContent="center"
      >
        <Toolbar>
          <Box
            component="img"
            src={logo_black_with_text_landscape}
            alt="Company Logo"
            sx={{ height: 50, marginRight: 2 }}
          />
        </Toolbar>
      </AppBar>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100vh" }}
      >
        <Grid item size={8}>
          <Typography
            variant="h3"
            sx={{ mx: 16, mt: 8, color: "#212427", fontFamily: "Poppins" }}
          >
            Explore what is in your neighbourhood, with sidewalk.
          </Typography>
          <br />
          <div style={{display: 'flex', justifyContent: 'center', margin: 10}}>
          <DownloadContainerComponent />
          </div>
        </Grid>
        <Grid item xs={4} container alignItems="center" justifyContent="center">
          <DeviceFrameset
            device="iPhone X"
            color="black"
            style={{
              backgroundColor: "blue",
            }}
          >
            <Box px={2} mt={4}>
              <VendorMobileView deviceWidth={344} />
            </Box>
          </DeviceFrameset>
        </Grid>
      </Grid>
    </>
  );
};

export default VendorDesktopView;
