import MotionPhotosOffIcon from "@mui/icons-material/MotionPhotosOff";
import StarIcon from "@mui/icons-material/Star";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../App.css";
import logo from "../../assets/logo_no_text.png";
import { theme as sidewalkTheme } from "../../theme";
import Footer from "../components/FooterComponent";
import { MapView } from "../components/MapComponent";
import DownloadContainerComponent from "./components/DownloadContainerComponent";
import { Gallery } from "react-grid-gallery";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const VendorMobileView = ({deviceWidth = null}) => {
  console.log("VendorMobileView");
  const navigate = useNavigate();
  const { slug = "adeats" } = useParams();
  const [storeData, setStoreData] = useState(null);
  const [storeMenuPhotos, setStoreMenuPhotos] = useState([]);
  const [storeFrontPhotos, setStoreFrontPhotos] = useState([]);
  const [storeLocationOpen, setStoreLocationOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Items");
  const [error, setError] = useState(null);

  const gridRef = useRef(null); // Create a ref for the Grid
  const [gridWidth, setGridWidth] = useState(0); // State to store Grid width

  const [index, setIndex] = useState(-1);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        setGridWidth(entries[0].contentRect.width); // Update the width when it changes
      }
    });

    if (gridRef.current) {
      resizeObserver.observe(gridRef.current); // Observe the Grid
    }

    return () => {
      if (gridRef.current) {
        resizeObserver.unobserve(gridRef.current); // Cleanup
      }
    };
  }, []);

  // Fetch data for storefront and photos
  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/storefronts/${slug}/`
        );
        if (!response.ok) throw new Error("Storefront does not exist");
        const data = await response.json();
        setStoreData(data);
      } catch (err) {
        console.error(err.message);
        setError(err.message);
        navigate("/");
      }
    };

    const fetchPhotosData = async () => {
      try {
        const menuPhotos = await fetchPhotos(`${slug}/menu_photos/`);
        const storefrontPhotos = await fetchPhotos(`${slug}/storefront_photos/`);
        setStoreMenuPhotos(menuPhotos);
        setStoreFrontPhotos(storefrontPhotos);
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchPhotos = async (endpoint) => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/storefronts/${endpoint}`);
      if (!response.ok) throw new Error("Error fetching photos");
      return await response.json();
    };

    fetchVendorData();
    fetchPhotosData();
  }, [slug, navigate]);

  const handleClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const galleryImages = useMemo(() => {
    const photos = selectedTab === "Items" ? storeMenuPhotos : storeFrontPhotos;
    return photos.map((photo) => ({
      src: photo.menu_photo || photo.storefront_photo,
      caption: photo.caption || `${selectedTab} Photo`,
    }));
  }, [selectedTab, storeMenuPhotos, storeFrontPhotos]);

  const margin = 5; // Adjust if needed
  const imageSize = Math.floor(((deviceWidth ?? gridWidth) - margin * 6) / 3); // Deduct margins for 3 images

 

  const handlePhotoClick = (index, item) => setIndex(index);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <br />
      <div className="avatar-container">
        <Avatar
          alt="Logo"
          src={storeData?.logo || logo}
          sx={{
            width: 100,
            height: 100,
            border: "1px solid gray",
            padding: storeData?.logo ? 0 : 2,
          }}
        />
      </div>
      <Typography component="div" gutterBottom>
        <Box className="store-name-box">{storeData?.name}</Box>
      </Typography>
      <Typography
        component="div"
        gutterBottom
        className="store-details-container"
      >
        <Box>{storeData?.cuisine}</Box>
        <Box>{"|"}</Box>
        <Box>
          {storeData?.rating ? (
            <div className="rating-container">
              <Typography component="div">{storeData?.rating}</Typography>
              <StarIcon
                fontSize="small"
                sx={{ color: sidewalkTheme.colors.yellow }}
              />
            </div>
          ) : (
            <Typography component="div">No Rating Yet</Typography>
          )}
        </Box>
      </Typography>

      <Typography variant="body1" component="div">
        <Box>{storeData?.description}</Box>
      </Typography>
      <Grid
        container
        alignItems="center"
        sx={{
          marginTop: 2,
          paddingLeft: 1,
          paddingRight: 1,
          display: "flex",
        }}
      >
        <Grid
          item
          xs="auto"
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {storeLocationOpen ? (
            <div className="pulse-container">
              <div className="pulse-circle"></div>
              <div className="solid-circle"></div>
            </div>
          ) : (
            <MotionPhotosOffIcon sx={{ color: sidewalkTheme.colors.red }} />
          )}
        </Grid>

        <Grid item xs sx={{ textAlign: "left", ml: 1 }}>
          <Typography
            variant="body1"
            component="div"
            className="text-container"
          >
            <Box sx={{ fontWeight: "bold", fontSize: 15, marginLeft: 1 }}>
              {`${storeData?.name} is currently ${storeLocationOpen ? "open" : "closed"
                }`}
            </Box>
          </Typography>
        </Grid>
      </Grid>

      <br />
      <MapView
        slug={slug}
        setStoreLocationOpen={setStoreLocationOpen} />
      <br />

      <ButtonGroup variant="text" aria-label="outlined primary button group">
        <Button
          onClick={() => handleClick("Photos")}
          style={{
            backgroundColor: selectedTab === "Photos" ? "teal" : "transparent",
            color: selectedTab === "Photos" ? "white" : "black",
          }}
          className="button-common"
        >
          Photos
        </Button>
        <Button
          onClick={() => handleClick("Items")}
          style={{
            backgroundColor: selectedTab === "Items" ? "teal" : "transparent",
            color: selectedTab === "Items" ? "white" : "black",
            marginRight: 10,
            marginLeft: 10,
          }}
          className="button-common"
        >
          Items
        </Button>
        <Button
          onClick={() => handleClick("Reviews")}
          style={{
            backgroundColor: selectedTab === "Reviews" ? "teal" : "transparent",
            color: selectedTab === "Reviews" ? "white" : "black",
          }}
          className="button-common"
        >
          Reviews
        </Button>
      </ButtonGroup>
      {!deviceWidth && <DownloadContainerComponent/>}
      {
        ["Items", "Photos"].includes(selectedTab) ?
      <div
      ref={gridRef}
      >
      <Gallery
        images={galleryImages}
        enableImageSelection={false}
        rowHeight={imageSize} // Set height of each image
        margin={margin} // Set spacing between images
        thumbnailStyle={() => ({
          objectFit: "cover",
          width: imageSize, // Ensure the calculated size fits the screen
          height: imageSize,
        })}
        onClick={handlePhotoClick}
      />
      </div>
          : <div style={{marginTop: '20px'}}><Typography>Soon. Like, really soon. Pinky promise. 😶</Typography></div>
}

      {/* Lightbox */}
      <Lightbox
        slides={galleryImages}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />

      <br />
      <Footer />
    </>
  );
};

export default VendorMobileView;
